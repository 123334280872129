const properties = {
    alittlehi: {
        image: "https://scontent-iad3-2.xx.fbcdn.net/v/t39.30808-6/325377349_856571225624121_80240713018991906_n.jpg?stp=cp6_dst-jpg&_nc_cat=111&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=o2hK-tpbHqcAX83jaoE&_nc_ht=scontent-iad3-2.xx&oh=00_AfCczhHr0YS-FCnSYkSmRwLZRWyd1omqP5mTVofLB4hqJQ&oe=63CD95B5",
        name: "",
        description: "Quesadillas tacos with habanero cheddar cheese and Monterrey jack in the shells lettuce chorizo and ground beef mix with salsa Verde and cotija and cilantro mix"
    },
    kirkwood: {
        image: "https://scontent-iad3-2.xx.fbcdn.net/v/t39.30808-6/325784727_1012631176374512_2691961991328803929_n.jpg?stp=cp6_dst-jpg&_nc_cat=103&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=A-fisJPCMjkAX_EkvId&_nc_oc=AQmX9QLtf8m33dydGqJxVCq1WJNU8OgczMmcWmiU0gdZfKpuL-rafip8whcDlq4DZF4&_nc_ht=scontent-iad3-2.xx&oh=00_AfChRxFHfODG-g5uvqvJq5-p-hKrPF3i2lUXt6wz_UEdYw&oe=63CDCD0D",
        name: "Toasted and roasted",
        description: "2 hotdogs in a puff pastry, with chili, roasted corn 🌽, shredded cheese 🧀, white onion, fried egg 🍳, and chives."
    },
    mccausland: {
        image: "https://scontent-iad3-2.xx.fbcdn.net/v/t39.30808-6/326368776_6110275202325745_8792377125868381725_n.jpg?stp=cp6_dst-jpg&_nc_cat=101&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=CGnfhzyHDHMAX___oAz&_nc_ht=scontent-iad3-2.xx&oh=00_AfBD_s-qGjK4g46mbjXcwL5ECdUrMfYyx5TS-BismvTUbg&oe=63CC73D9",
        name: "Kellie Pickler",
        description: "Double burg with cheddar, lettuce, tomato, fried pickle spears, bbq ranch, garlic toasted potato bun"
    },
    downtownstl: {
        image: "https://scontent-iad3-2.xx.fbcdn.net/v/t39.30808-6/323714450_636361114929756_948705686455806465_n.jpg?stp=cp6_dst-jpg&_nc_cat=100&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=mbCbh6ZwMRoAX-rUeXv&_nc_oc=AQlSxTqW3KuE1yrhG_7PIOWHNm3BE5OH-kfqtrL-4-fiHtz0DGxVhHLo-MTe6v172UY&_nc_ht=scontent-iad3-2.xx&oh=00_AfCex_Oqm8XtCAreHxYX6R0oWCWCEXxM_FXXjQB4B8fGhQ&oe=63CCE592",
        name: "Taco-lahoma",
        description: "Double taco 🌮 burg seared with jalapeños and taco sauce, pepper 🌶️ jack cheese 🧀, lettuce 🥬 tomato 🍅 pickled red onion 🧅, avocado 🥑 ranch, potato bun"
    },
    ofallonil: {
        image: "https://scontent-iad3-2.xx.fbcdn.net/v/t39.30808-6/325627110_723009702680259_5281494303579392860_n.jpg?stp=cp6_dst-jpg&_nc_cat=100&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=n99HxpoCJiUAX-Cp30S&_nc_ht=scontent-iad3-2.xx&oh=00_AfBgyKJppaV-ca4KCV4MLdBiNvAtyv98HOkTTlKyBevJ7g&oe=63CDA9EA",
        name: "TASTE OF PHILLY",
        description: "Sliced Prime Rib Orange & Red Peppers 🌶 Onions Pepperjack Cheese 🧀 On A Grilled Flour Tortilla Topped With White Queso Cheese & Italian Seasoning!😱😱😱"
    }
};

export { properties };